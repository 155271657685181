/* eslint-disable import/first */
import jQuery from 'jquery';

// @ts-ignore
window.jQuery = jQuery;
// @ts-ignore
window.$ = jQuery;

import '../css/landing/theme.scss';

import '../src/landing_plugins';
import '../src/esign_handler';
import '../src/landing/dropdown';
import '../src/landing/polyfills';
import '../src/landing/navbar';
import '../src/landing/modal';
import '../src/landing/smooth-scroll';
import '../src/landing/choices';
import '../src/landing/popovers';

// @ts-ignore
window.confetti = require('canvas-confetti');

import.meta.glob('../css/img/**/*.*', { eager: true });
